
import { Vue, Component } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class About extends Vue {
  private article: any = {}
  private mounted () {
    /* const mySwiper = new Swiper('.swiper-container', {
      autoplay: false,
      direction: 'vertical',
      mousewheel: true,
      pagination: {
        el: '.swiper-pagination'
      }
    }) */
    // api.articleGet(2).then((res) => this.article = res.result) 
  }
}
